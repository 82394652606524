import React from 'react';
import { useMedia } from '../utils/hooks';
import SEO from '../components/seo';
import WashPlansSection from '../sections/washplans';
import PricingSection from '../sections/pricing';
import Footer from '../components/footer';
import FinsWashIcon from '../components/icons/finswash';

import FAQSection from '../sections/faqs';
import { Link } from 'gatsby';

import '../styles/promo-page.css';
import { LandingPageHeader } from '../components/header/promo-page';

function noop() {
  return;
}

const PromoSplashSalePage: React.FC<any> = () => {
  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)', '(min-width: 0px)'],
    [
      { width: '206', height: '40' },
      { width: '249.18', height: '47.54' },
      { width: '166.12', height: '31.69' }
    ],
    { width: '206', height: '40' }
  );

  return (
    <div id="PromoTemplate" className="Promo-container">
      <LandingPageHeader title="Fins Car Wash: Splash Sale!" />

      <div
        style={{
          position: 'relative',
          display: 'flex'
        }}
      >
        <div
          style={{
            color: '#333',
            margin: '64px var(--site-padding)',
            maxWidth: 540
          }}
          className="splash-sale"
        >
          <h1>Splash Sale!</h1>
          <p>
            Times are tough but everyone deserves a clean car. Take advantage of
            our discounted single washes and wash plans for a limited time only.
            Offer only valid at FINS Greensboro, NC locations.
          </p>

          <img
            src="/img/splashsale@2x.png"
            style={{
              boxShadow:
                '0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2)'
            }}
          />
        </div>

        <img
          src="/img/Fin_With_Splash.png"
          className="splash-sale-fin"
          style={{ position: 'absolute', right: 0 }}
        />
      </div>

      <PricingSection sale title={false} />

      <Link className="PromoTemplate__back-link" to="/#Promotions">
        &#x3c;&#x3c; Back To Promotions
      </Link>
      <Footer items={[]} onNavItemSelected={noop} />
    </div>
  );
};

export default PromoSplashSalePage;
